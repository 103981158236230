import { configureStore } from '@reduxjs/toolkit'
import selectedRaceReducer from './selectedRaceSlice'
import editModeReducer from './editModeSlice'


export default configureStore({
  reducer: {
    selectedRace: selectedRaceReducer,
    editMode: editModeReducer
  }
})