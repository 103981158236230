
/*export const properties = {
    POST_API_GATEWAY_URL: "https://vpybjm6tp9.execute-api.eu-west-2.amazonaws.com/dev/update-race",
    GET_API_GATEWAY_URL: "https://vpybjm6tp9.execute-api.eu-west-2.amazonaws.com/dev/say-hello"
};
*/

export const properties = {
    POST_API_GATEWAY_URL: "https://api.f1allday.com/process-schedule",
    GET_API_GATEWAY_URL: "https://api.f1allday.com/process-schedule"
};