
import NavBarClass from '../NavBarf1allday';


export default function AdminPageFunction() {
    
    return (
        <>
            <NavBarClass/>
            <ButtonsGrid/>            
        </>
    );
}

function ButtonsGrid(){
    return (
        <>
            <span>
                <div className="admin-buttons-grid">
                    <div className="container-fluid">
                        <div className="row justify-content-center g-2">
                            <AdminButton label="Schedule" url="/admin-schedule"/>
                            <AdminButton label="Head-To-Head" url="https://www.f1allday.com/home"/>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row justify-content-center g-2">
                            <AdminButton label="Driver Standings" url="https://www.f1allday.com/home"/>
                            <AdminButton label="Constructors Standings" url="https://www.f1allday.com/home"/>
                        </div>
                    </div>
                </div>
            </span>            
        </>
    )
}

function AdminButton({label, url, target=null}){
    return (
        <div className="col-lg-6 col-xl-3">
            <div href={url} className="p-3 border bg-light" target="_blank">
                <a href={url} className="btn-headline-text"  target={target}>{label}</a>
            </div>
            
        </div>
    );

}



